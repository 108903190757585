<template>
  <div>
    <back-button to="/admin/">Back to Settings</back-button>
    <b-row align-h="end">
      <b-col cols="4">
        <b-button-group>
          <b-button class="avo-basic-btn mr-2" v-b-toggle.accordion-3 variant="outline-primary">
            <b-icon variant="info" class="mr-2" icon="list-ul"></b-icon>
            User list
          </b-button>
          <b-button class="avo-basic-btn mr-2" v-b-toggle.accordion-2 variant="outline-primary">
            <b-icon variant="info" class="mr-2" icon="person-plus-fill"></b-icon>
            Add a user
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <div class="accordion" role="tablist">
      <b-container style="margin-left:0px">
        <b-row>
          <b-col lg="12" xl="9">
            <b-collapse id="accordion-2" accordion="myaccordion">
              <span style="font-weight: 300; font-size: 36px">Add a user</span>
              <div class="pt-3">
                <invite-user @userInvited="userListChanged" />
              </div>
            </b-collapse>
          </b-col>
        </b-row>
        <!-- List of users panel -->
        <b-row>
          <b-col>
            <b-collapse id="accordion-3" visible accordion="myaccordion" class="mt-2">
              <div>
                <users-list @userListRefreshed="userListRefreshed" :refreshUserList="refreshUserList" />
              </div>
            </b-collapse>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import InviteUser from "../components/userRegistration/InviteUser.vue";
import UsersList from "../components/users/UsersList.vue";
import BackButton from "../components/BackButton.vue";

export default {
  name: "Users",
  components: {
    UsersList,
    InviteUser,
    BackButton,
  },
  data() {
    return {
      refreshUserList: false,
    };
  },
  methods: {
    userListChanged: function () {
      this.refreshUserList = true;

    },
    userListRefreshed: function () {
      this.refreshUserList = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: sans-serif;
  display: inline;
}
/* This ensures at least one panel in the accordion will always be open */
.not-collapsed {
  pointer-events: none !important;
  background: #F2F6FC !important;
}
</style>
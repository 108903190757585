<template>
<div id="users-list">
    <avo-alert :avoAlerts.sync="avoAlerts" />
    <b-overlay :show="!loaded" variant="white" blue='2px' opacity=0.85 rounded="sm">
    <div class="d-flex" style="margin-left:20px">
        <div class="user-header-text" style="margin-right:30px">
            List of users
        </div>
        <div>
        <b-input-group class="search-group">
            <b-input-group-prepend>
                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
            </b-input-group-prepend>
            <b-form-input
                @keyup="debounceUsers"
                @search="debounceUsers"
                class="user-search-input pl-1"
                type="search" v-model="search_params.search"
                placeholder="Try typing a user's name"/>                
            <b-input-group-append >
                <span class="divider"></span>
                <b-dropdown class="dropdown filter-dropdown" :boundary="'window'">
                    <template #button-content>
                    <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                    </template>
                    <b-dropdown-form class="d-flex flex-column align-items-start">
                    <span style="font-size:20px;font-weight:300">Filters</span>
                    
                    <div class="selecter-label">Assigned Practices</div>
                    <multiselect 
                        v-model="filters.practices"
                        :hide-selected="true"
                        :options="practices"
                        track-by="practice_id" 
                        label="name"
                        :multiple="true"
                        :show-labels="false"
                        :close-on-select="false"
                        placeholder="-- Select Practices --">
                    </multiselect>
                    
                    <div class="selecter-label">Assigned Payers</div>
                    <multiselect 
                        v-model="filters.payers"
                        :hide-selected="true"
                        :options="payers"
                        track-by="payer_id" 
                        label="name"
                        :multiple="true"
                        :show-labels="false"
                        :close-on-select="false"
                        placeholder="-- Select Payers --">
                    </multiselect>
                    
                    <div class="d-flex justify-content-start mt-4">
                        <b-button @click="doSearch" variant="outline-primary" class="avo-primary-btn mr-5">Apply</b-button>
                        <b-button @click="clearFilters" variant="outline-primary" class="avo-basic-btn">Clear Filters</b-button>
                    </div>
                    </b-dropdown-form>
                </b-dropdown>
            </b-input-group-append>
        </b-input-group>
        </div>
    </div>
    <div v-if="users.length > 0">
        <b-row v-for="(user, index) in users" :key="index" align-v="center" :class="user.active?'user-box':'user-box-disabled'">
            <b-col cols="4">
                <div :style="'disabled:' + user.active + '; padding-left:10px; border-left:3px solid ' + colors[index % 7]">
                    <div class="avo-text-light">
                        {{user.active ? 'Name' : 'Deactivated User'}}
                    </div>
                    <div :class="user.active?'user-name-text':''">
                        {{user.name}}
                    </div>
                </div>
            </b-col>
            <b-col cols="4">
                <div style="margin-right:20px">
                    <div class="avo-text-light">
                        Email
                    </div>
                    <div class="avo-text-bold">
                        {{user.email}}
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
                <div style="margin-right:20px">
                    <div class="avo-text-light">
                        Role(s)
                    </div>
                    <div class="avo-text-bold">
                        {{String(user.roles).replace(/,/g, ", ")}}
                    </div>
                </div>
            </b-col>


            <b-col cols="1">
                <div>
                        <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                            <template #button-content>
                            <b-icon icon="three-dots"></b-icon>
                            </template>
                            <b-dropdown-item @click="viewUserProfileModal(index)"><b-icon variant="secondary" class="mr-2" icon="person"></b-icon> Edit user profile</b-dropdown-item>
                            <b-dropdown-item @click="viewUserDeactivateModal(index)"><b-icon variant="secondary" class="mr-2" :icon="user.active ? 'person-x-fill' : 'person-plus-fill'"></b-icon> {{user.active ? 'Deactivate user' : 'Reactivate user'}}</b-dropdown-item>
                            <b-dropdown-item @click="viewPasswordResetModal(index)"><b-icon variant="secondary" class="mr-2" icon="key-fill"></b-icon> Reset password</b-dropdown-item>
                            <b-dropdown-item @click="viewUserRolesModal(index, user)"><b-icon variant="secondary" class="mr-2" icon="briefcase"></b-icon> Update roles</b-dropdown-item>
                            <b-dropdown-item v-if="userNeedsPracticeAssociated(user)" @click="viewUserPracticeModal(index, user)"><b-icon variant="secondary" class="mr-2" icon="building"></b-icon> Update practice assignment</b-dropdown-item>
                            <b-dropdown-item v-if="userNeedsProviderAssociated(user)" @click="viewUserProviderModal(index, user)"><b-icon variant="secondary" class="mr-2" icon="person-badge"></b-icon> Update provider for user</b-dropdown-item>
                            <b-dropdown-item v-if="userNeedsWorkerAssignments(user)" @click="viewUserAssignmentsModal(index)"><b-icon variant="secondary" class="mr-2" icon="card-checklist"></b-icon> Update assignments</b-dropdown-item>
                        </b-dropdown>
                        
                        <b-modal class="modal" :id="'user-profile-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Update user profile
                            </div>
                            <div class="avo-text-light mb-4">
                            <!-- {{user}} -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                        id="user-profile-given-name-label"
                                        label="First name"
                                        label-for="user-profile-given-name">
                                        <b-form-input
                                            id="user-profile-given-name"
                                            v-model="editProfileForm.givenName"
                                            type="text"
                                            :placeholder="user.given_name"
                                            required
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>

                                    <b-col>
                                        <b-form-group
                                        id="user-profile-family-name-label"
                                        label="Last name"
                                        label-for="user-profile-family-name">
                                        <b-form-input
                                            id="user-profile-family-name"
                                            v-model="editProfileForm.familyName"
                                            type="text"
                                            :placeholder="user.family_name"
                                            required
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                    
                                <b-form-row>
                                    <b-col cols="6">
                                        <b-form-group
                                        id="user-profile-nick-name-label"
                                        label="Nickname"
                                        label-for="user-profile-nick-name">
                                        <b-form-input
                                            id="user-profile-nick-name"
                                            v-model="editProfileForm.nickName"
                                            type="text"
                                            :placeholder="user.nick_name"
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideUserProfileModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="updateUserProfile(index, users[index].worker_id)" variant="primary">Update user profile</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'reset-password-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Send password reset email to {{users[index].name}}?
                                <br/><b-icon variant="secondary" class="mt-2 mb-2" font-scale="5" icon="key"></b-icon>
                                <b-icon variant="secondary" class="ml-2 mt-2 mb-2" font-scale="5" icon="mailbox"></b-icon>
                            </div>
                            <div class="avo-text-light mb-4">
                                {{users[index].name}} will receive an email with a link to reset their password.
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hidePasswordResetModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="sendPasswordReset(index, users[index].user_id)" variant="primary">Yes, reset password</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'user-practice-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Select a new practice to associate to {{users[index].name}}:
                                <br/><b-icon variant="secondary" class="mt-2 mb-2" font-scale="5" icon="building"></b-icon>
                            </div>
                            <div class="avo-text-light mb-4">
                                <practice-multiselect v-model="selectedPractice"/>
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideUserPracticeModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="updateUserPractice(index, users[index].user_id, selectedPractice.practice_id)" variant="primary">Update practice assignment</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'user-provider-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Select a new provider to associate to {{users[index].name}}:
                                <br/><b-icon variant="secondary" class="mt-2 mb-2" font-scale="5" icon="person-badge"></b-icon>
                            </div>
                            <div class="avo-text-light mb-4">
                                <provider-multiselect v-model="selectedProvider" :multiple="false" :close-on-select="true"/>
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideUserProviderModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="updateUserProviders(index, users[index].user_id, selectedProvider.provider_id)" variant="primary">Update provider assignment</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'user-deactivate-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                {{user.active ? 'Deactivate' : 'Reactivate'}} account for {{users[index].name}}?
                                <br/>
                                <b-icon variant="secondary" class="mt-2 mb-2" font-scale="5" :icon="user.active ? 'person-x-fill' : 'person-plus-fill'"></b-icon>
                            </div>
                            <div class="avo-text-light mb-4">
                                Deactivated users are unable to log in.
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideUserDeactivateModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="deactivateUser(index, users[index].user_id, user.active)" variant="primary">{{user.active ? 'Yes, deactivate user' : 'Yes, reactivate user'}}</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'user-roles-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mb-4 bold" style="text-align:center">
                                Update roles for {{users[index].name}}:
                            </div>
                                <b-form class="mb-4">
                                    <multiselect
                                        v-model="form.roles" 
                                        :options="rolesOptions" 
                                        :show-labels="false"
                                        :hide-selected="true"
                                        :close-on-select="false" 
                                        track-by="text"
                                        label="text"
                                        :multiple="true"
                                        placeholder="-- Select Roles --"
                                        required>
                                    </multiselect>
                                </b-form>
                                <div style="text-align:right">
                                    <b-button-group style="text-align:right">
                                    <b-button class="avo-basic-btn ml-2" @click="hideUserRolesModal(index)">Cancel</b-button>
                                    <b-button class="avo-primary-btn ml-2" @click="updateUserRoles(index, users[index].user_id, form.roles)" variant="primary">Update roles</b-button>
                                    </b-button-group>
                                </div>
                        </b-modal>

                        <worker-assignments-update :worker="{worker_id: users[index].worker_id, name: users[index].name}" :index="index"
                        :practicesOptions="practicesOptions" :payersOptions="payersOptions"
                        :payersGroupsOptions="payersGroupsOptions"
                        @updated="getUsers(); showSuccessMessage('User assignments updated');" />
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="page" align="center" />
            </b-col>
        </b-row>
    </div>
    <div v-else-if="loaded" class="user-header-text" style="text-align:center">
        No users found
    </div>
    </b-overlay>
</div>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'
import WorkerAssignmentsUpdate from '../workers/WorkerAssignmentsUpdate.vue'
import AvoAlert from '../../components/AvoAlert.vue'

export default {
  name: 'UsersList',
  mixins: [alertsMixin],
  components: {
      AvoAlert,
      Multiselect,
      PracticeMultiselect,
      ProviderMultiselect,
      WorkerAssignmentsUpdate
  },
  props: ['refreshUserList'],
  data(){
    return {
        users: [],
        practices: [],
        payers: [],
        filters: {
            practices: [],
            payers: [],
        },
        search_params: {
            search: "",
            practice_ids: "",
            payer_ids: ""
        },

        rolesOptions: [],
        practicesOptions: [],
        payersOptions: [],
        payersGroupsOptions: [],
        form: {
              roles: [],
        },
        editProfileForm: {
            givenName: "",
            familyName: "",
            nickName: "",
        },
        selectedPractice: null,
        selectedProvider: null,
        
        perPage: 12,
        totalRows: 1,
        page: 1,
        colors: ["#41B0A5", "#7550CB", "#15ACC1", "#E68E5D", "#FE679F", "#67B765", "#EEC05A"],
        loaded: false,
        
        showSuccess: false,
    }
  },
  methods: {
    getFilters: function(){
        const url = make_url("/reports/workers/filters");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                self.hideError();
                const data = response.data;
                self.practices = data.practices;
                self.payers = data.payers;
                return data || [];
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    getUsers: function(){
        var params = this.search_params;
        params.per_page = this.perPage;
        params.page = this.page;
        //if(this.sortBy){
        //    params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
        //}
        const url = make_url("/reports/workers");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            if (response) {
                const users = response.data.workers;
                self.users = users;
                self.totalRows = response.data.pagination.total;
                self.perPage = response.data.pagination.per_page;
                self.loaded = true;
                return users || [];
            }
        }).catch(function(error){
            self.showError(error);
            self.loaded = true;
        });
        
        return promise;
    },
    doSearch: function(){
        this.page = 1;
        this.getUsers();
    },
    clearFilters: function(){
        this.filters.practices = [];
        this.filters.payers = [];
        this.search_params.practice_ids = [];
        this.search_params.payer_ids = [];
        this.getUsers();
    },
    selectUser(index){
        this.$store.commit("selectUser", this.users[index]);
    },
    
    //error methods
    showSuccessMessage(alertMessage){
        this.showAvoSuccess(alertMessage);
    },
    showError(alertMessage){
        this.showAvoAlert(alertMessage);
    },
    hideError(){
        this.hideAvoAlerts();
    },

    //modals
    viewUserProfileModal(index){
            this.$bvModal.show('user-profile-modal-'+index);
    },
    hideUserProfileModal(index){
            this.$bvModal.hide('user-profile-modal-'+index);
            
            this.editProfileForm.givenName = ""; 
            this.editProfileForm.familyName = "";
            this.editProfileForm.nickName = "";
    },
    updateUserProfile(index, worker_id){
        const vm = this;
        const url = make_url("/workers/"+worker_id).toString();

        // create data object with givenName and familyName if they are not empty
        const data = {};
        if(vm.editProfileForm.givenName){
            data.given_name = vm.editProfileForm.givenName;
        }
        if(vm.editProfileForm.familyName){
            data.family_name = vm.editProfileForm.familyName;
        }
        if(vm.editProfileForm.nickName){
            data.nick_name = vm.editProfileForm.nickName;
        }

        const promise = authenticated_request({
            method: "put",
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                vm.hideUserProfileModal(index);
                vm.getUsers();
                vm.showSuccessMessage("User profile updated");
                return response || [];
            }
        }).catch(function(error){
            vm.showError(error);
        });


    },

    viewPasswordResetModal(index){
            this.$bvModal.show('reset-password-modal-'+index);
    },
    hidePasswordResetModal(index){
            this.$bvModal.hide('reset-password-modal-'+index);
    },
    sendPasswordReset(index, user_id){
        const vm = this;

        const password_reset_url = make_url("/users/" + user_id + "/passwordreset");

        const promise = authenticated_request({
            method: "post",
            url: password_reset_url,
        })
        .then(function(response){
                vm.$emit('passwordResetSent');
                vm.showAlert = false;
                if (response.status == 201) {
                    vm.showSuccessMessage("Password reset sent");
                }
            }, function(error){
                console.log("Trying to show alert");
                vm.showError(error);
        }).finally(
            function(){
                vm.hidePasswordResetModal(index);
            }
        );
        return promise;
    },
    viewUserDeactivateModal(index){
            this.$bvModal.show('user-deactivate-modal-'+index);
    },
    hideUserDeactivateModal(index){
            this.$bvModal.hide('user-deactivate-modal-'+index);
    },
    deactivateUser(index, user_id, active){

        const vm = this;

        const deactivate_user_url = make_url("/users/" + user_id + "/activate");

        const data = {'active': active ? false : true }

        const promise = authenticated_request({
            method: "post",
            url: deactivate_user_url,
            data: data,
        })
        .then(function(response){
                vm.$emit('userAccountDeactivated');
                vm.showAlert = false;
                if (response.status == 204) {
                    vm.getUsers();
                    vm.showSuccessMessage(active ? "User account deactivated successfully" : "User account reactivated successfully");
                    vm.hideUserDeactivateModal(index);
                }
            }, function(error){
                this.showError(error);
        });
        return promise;
    },
    activateUser(index, user_id){

        const vm = this;

        const deactivate_user_url = make_url("/users/" + user_id + "/activate");

        const data = {'active': true }

        const promise = authenticated_request({
            method: "post",
            url: deactivate_user_url,
            data: data,
        })
        .then(function(response){
                vm.$emit('userAccountDeactivated');
                vm.showAlert = false;
                if (response.status == 204) {
                    vm.getUsers();
                    vm.showSuccessMessage("User account activated");
                    vm.hideUserDeactivateModal(index);
                }
            }, function(error){
                vm.showError(error);
        });
        return promise;
    },
    userRolesDict(user){
        // Returns an object with keys = user role names, values = user role ids for the user
        return user.roles.split(",").map((rl, idx)=>{return {text: rl, value: user.role_ids.split(",")[idx]}}); //zip
    },
    userNeedsWorkerAssignments(user){
        if (!user.roles) {
            return false;
        }
        const userRoleList = user.roles.split(',')
        return (userRoleList.includes('Worker') || userRoleList.includes('Issues Worker') || userRoleList.includes('Poster'));
    },
    userNeedsPracticeAssociated(user){
        if (!user.roles) {
            return false;
        }
        const userRoleList = user.roles.split(',')
        return (userRoleList.includes('Practice Admin'));
    },
    userNeedsProviderAssociated(user){
        if (!user.roles) {
            return false;
        }
        const userRoleList = user.roles.split(',')
        return (userRoleList.includes('Physician') || userRoleList.includes('Administrative Physician'));
    },
    viewUserPracticeModal(index){
        this.$bvModal.show('user-practice-modal-'+index);
    },
    hideUserPracticeModal(index){
        this.$bvModal.hide('user-practice-modal-'+index);
    },
    updateUserPractice(index, user_id, new_practice_id){
        const vm = this;

        const user_practice_url = make_url("/practices/"+new_practice_id+"/users/update");

        var data = {
            user_id: user_id,
        }

        const promise = authenticated_request({
            method: "put",
            url: user_practice_url,
            data: data
        })
        .then(function(response){
                vm.$emit('userPracticeUpdated');
                vm.showAlert = false;
                if (response.status == 200 || response.status == 201) {
                    vm.getUsers();
                    vm.showSuccessMessage("User practice assignment updated");
                    vm.hideUserPracticeModal(index);
                }
            }, function(error){
                vm.showError(error);
        });
        return promise;
    },
    viewUserProviderModal(index, user){
        if (user.providers) {
            this.selectedProvider = user.providers;
        }
        this.$bvModal.show('user-provider-modal-'+index);
    },
    hideUserProviderModal(index){
        this.$bvModal.hide('user-provider-modal-'+index);
    },
    updateUserProviders(index, user_id, new_provider_id){
        const vm = this;

        const clear_user_providers_url = make_url("/providers/users/" + user_id).toString();

        const user_provider_url = make_url("/providers/" + new_provider_id).toString();

        var data = {
            user_id: user_id,
        }

        const clear_users_promise = authenticated_request({
            method: "post",
            url: clear_user_providers_url,
        })

        const add_users_promise = authenticated_request({
            method: "put",
            url: user_provider_url,
            data: data
        })
        .then(function(response){
                vm.$emit('userProviderUpdated');
                vm.showAlert = false;
                if (response.status == 200 || response.status == 201 || response.status == 204) {
                    vm.getUsers();
                    vm.showSuccessMessage("User provider assignment updated");
                    vm.hideUserProviderModal(index);
                }
            }, function(error){
                vm.showError(error);
        });

        const promise = clear_users_promise.then(add_users_promise)

        return promise;
    },
    viewUserRolesModal(index, user){
        if (user.roles == "") {
            this.form.roles = []
        } else {
            this.form.roles = this.userRolesDict(user);
        }
        this.$bvModal.show('user-roles-modal-'+index);
    },
    hideUserRolesModal(index){
        this.$bvModal.hide('user-roles-modal-'+index);
    },
    updateUserRoles(index, user_id, rolesData){
        const vm = this;

        const user_roles_url = make_url("/roles/users");

        const roles = rolesData.map((item) => item.value);

        var data = {
            user_id: user_id,
            roles: roles,
        }

        const promise = authenticated_request({
            method: "post",
            url: user_roles_url,
            data: data
        })
        .then(function(response){
                vm.$emit('userRolesUpdated');
                vm.showAlert = false;
                if (response.status == 200) {
                    vm.getUsers();
                    vm.showSuccessMessage("User roles updated");
                    vm.hideUserRolesModal(index);
                }
            }, function(error){
                vm.showError(error);
        });
        return promise;
    },
    viewUserAssignmentsModal(index){
        this.$bvModal.show('worker-assignments-modal-'+index);
    },
  },
  computed: {
      filterCount: function() {
          var count = 0;
          if (this.search_params['practice_ids'].length > 0){
              count = count + 1;
          }
          if (this.search_params['payer_ids'].length > 0){
              count = count + 1;
          }
          return count;
      },
      filterInputLabel: function(){
          const count = this.filterCount;
          if(count == 0) {
               return "Add Filters";
          } else {
               return count + " Filter(s)";
          }
      },
  },
  watch: {
      'filters.practices': function(newValues) {
          this.search_params.practice_ids = newValues.map(obj => obj.practice_id).join();
      },
      'filters.payers': function(newValues) {
          this.search_params.payer_ids = newValues.map(obj => obj.payer_id).join();
      },
      page: function() {
          this.getUsers();
      },
      refreshUserList: function() {
          if (this.refreshUserList == true) {
            console.log("refreshUserList prop received by child element");
            this.getUsers();
            this.$emit('userListRefreshed');
      }},
  },
  created(){
      this.getUsers();
      this.debounceUsers = debounce(this.doSearch, 900);
  },
  mounted(){
        this.getFilters();

        var vm = this;
        // Get available roles to assign
        authenticated_request({
            method: "get",
            url: make_url("/roles"),
        }).then(function(response){
            const roleData = response.data.roles;
            for (var index = 0; index < roleData.length; index++){
            vm.rolesOptions.push({value: roleData[index].role_id, text: roleData[index].name});
            }
        });
        // Get available payers to assign
        authenticated_request({
            method: "get",
            url: make_url("/payers"),
        }).then(function(response){
            const payersData = response.data.payers;
            for (var index = 0; index < payersData.length; index++){
            vm.payersOptions.push({value: payersData[index].payer_id, text: payersData[index].name});
            }
        });
        // Get available payer groups to assign
        authenticated_request({
            method: "get",
            url: make_url("/payers/groups"),
        }).then(function(response){
            const payersGroupsData = response.data.payers_groups;
            for (var index = 0; index < payersGroupsData.length; index++){
            vm.payersGroupsOptions.push({value: payersGroupsData[index].payer_group_id, text: payersGroupsData[index].name});
            }
        });
        // Get available practices to assign
        authenticated_request({
            method: "get",
            url: make_url("/practices"),
        }).then(function(response){
            const practiceData = response.data.practices;
            for (var index = 0; index < practiceData.length; index++){
            vm.practicesOptions.push({value: practiceData[index].practice_id, text: practiceData[index].name});
            }
        });
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Text styling */
.user-box {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 20px;
    padding-right: 40px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    /* cursor: pointer; */
}
.user-header-text {
    font-weight: 400;
    color:var(--dark);
    font-size: 32px;
}
.user-name-text {
    font-weight: 400;
    color:var(--dark);
    font-size: 18px;
}
.user-box-disabled {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 20px;
    padding-right: 40px;
    border: 0px solid #C9D2DF;
    border-radius: 5px;
    color: #929BAA;
    background: #F7F8FA;
    /* cursor: pointer; */

}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .user-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .user-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 200px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}
.selecter-label {
    color: #64728C;
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom:5px;
}
</style>
<template>
  <b-overlay
    variant="transparent"
    :blur="null"
    :show="loading">
  <b-row>
    <b-col>
      <b-form-checkbox-group
        v-model="selected_ages"
        :options="age_ranges_options"
        buttons
        button-variant="outline-primary"
        class="checkbox-btn-group shadow mb-1"
        id="search-ages"
        @change="save_needed = true"
      />
    </b-col>
    <b-col cols="4">
      <b-button
        variant="info"
        size="sm"
        class="avo-basic-btn mr-1 mb-1"
        @click="
          selected_ages = [];
          save_needed = true;
        "
      >
        Clear
      </b-button>
      <b-button
        v-show="save_needed"
        v-b-modal.modal-1
        variant="info"
        size="sm"
        class="avo-basic-btn mr-1 mb-1"
        @click="undo"
        >Undo</b-button
      >
      <b-button
        v-show="save_needed && !dummy"
        v-b-modal.modal-1
        variant="primary"
        size="sm"
        class="avo-btn mr-1 mb-1"
        @click="addData"
        >Save</b-button
      >
    </b-col>
  </b-row>
  </b-overlay>
</template>

<script>
import {
  make_url,
  authenticated_request,
  parseAgeRanges,
} from "./../../common.js";

export default {
  name: "WorkerAgeRangeAssignments",
  props: {
    workerId: {
      type: Number,
      required: true,
    },
    dummy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      current_ranges: [],

      selected_ages: [],

      save_needed: false,
      loading: false,

      age_ranges_options: [
        { text: "0-30 days", value: "0,30" },
        { text: "31-60 days", value: "31,60" },
        { text: "61-90 days", value: "61,90" },
        { text: "91-120 days", value: "91,120" },
        { text: "121+ days", value: "121,730" },
      ],
    };
  },
  methods: {
    getData() {
      console.log("Getting data");
      const vm = this;
      vm.loading = true;
      const url = make_url(`/workers/${this.workerId}/age_ranges`);
      authenticated_request({
        url: url,
        method: "get",
      }).then((response) => {
        vm.selected_ages = parseAgeRanges(response.data.age_ranges);
        vm.save_needed = false;
        vm.current_ranges = vm.selected_ages;
      }).finally(() => {
        vm.loading = false;
      });
    },
    undo() {
      this.selected_ages = this.current_ranges;
      this.save_needed = false;
    },
    addData() {
      const vm = this;
      vm.loading = true;
      const url = make_url(`/workers/${this.workerId}/age_ranges`);
      authenticated_request({
        url: url,
        method: "post",
        data: {
          age_range: this.new_age_ranges,
        },
      }).then((response) => {
        console.log(response.data);
        vm.save_needed = false;
        vm.current_ranges = vm.selected_ages;
      }).finally(() => {
        vm.loading = false;
      });
    },
  },
  computed: {
    new_age_ranges: function () {
      return this.selected_ages.join();
    },
  },
  watch: {
    workerId: {
      handler: function () {
        this.getData();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Styling for claim age button group */
.checkbox-btn-group {
  width: 100%;
  min-height: 40px;
}
::v-deep .checkbox-btn-group .btn-outline-primary {
  border: 1px solid #c9d2df;
  padding-top: 9px;
  font-size: 14px;
  color: var(--gray);
}
::v-deep .checkbox-btn-group .btn-outline-primary:hover {
  background-color: #f2f6fc;
  color: var(--gray-800);
}
::v-deep .checkbox-btn-group .btn-outline-primary:active,
::v-deep .checkbox-btn-group .btn-outline-primary.active {
  border: 1px solid var(--blue) !important;
  background-color: #f2f6fc !important;
  color: var(--dark);
}
::v-deep .checkbox-btn-group .btn-outline-primary:focus,
::v-deep .checkbox-btn-group .btn-outline-primary.focus {
  box-shadow: none !important;
}
</style>
<template>
    <b-modal class="modal" size="lg" :id="(index != null) ? 'worker-assignments-modal-'+index : 'worker-assignments-modal'" hide-footer hide-header
    @shown="getWorkerAssignments()">
        <div class="avo-header-text mb-4 bold" style="text-align:center">
            Update assignments for {{worker.name}}:
        </div>
            <b-form>
                <b-form-row>
                    <b-col>
                    <b-form-group
                        label="Practices:">
                        <multiselect
                            v-model="form.practices" 
                            :show-labels="false"
                            :options="practicesOptions"
                            :close-on-select="false"
                            :hide-selected="true"
                            track-by="text"
                            label="text"
                            :disabled="optionsLoading"
                            placeholder="-- Select Practices --"
                            :multiple="true"
                            required>
                        </multiselect>
                    </b-form-group>
                    </b-col>
                </b-form-row>

                <b-form-row>
                    <b-col>
                    <b-form-group
                        label="Payers:">
                        <multiselect
                            v-model="form.payers" 
                            :show-labels="false"
                            :options="payersOptions"
                            :close-on-select="false" 
                            :hide-selected="true"
                            track-by="text"
                            label="text"
                            :disabled="optionsLoading"
                            placeholder="-- Select Payers --"
                            :multiple="true"
                            required>
                        </multiselect>
                    </b-form-group>
                    </b-col>
                    <b-col v-if="payersGroupsOptions && (payersGroupsOptions.length > 0)">
                    <b-form-group
                        label="Add by Payer Group:">
                        <multiselect
                            :show-labels="false"
                            :value="selectedPayerGroups"
                            :options="payersGroupsOptions"
                            :close-on-select="false"
                            :taggable="true"
                            :multiple="true"
                            track-by="value"
                            label="text"
                            :disabled="optionsLoading"
                            placeholder="-- Select Payer Group --"
                            @select="addPayersByGroup"
                            @remove="popPayerGroup">
                        </multiselect>
                    </b-form-group>
                    </b-col>
                </b-form-row>

                <b-form-row>
                    <b-col>
                    <b-form-group
                        label="CPT Codes:">
                        <multiselect
                            openDirection="bottom"
                            placeholder="Search by code or description" 
                            label="code"
                            :custom-label="codeWithDescription"
                            v-model="form.codes"
                            track-by="code"
                            :options="selectableCPTCodes"
                            :multiple="true"
                            :disabled="optionsLoading"
                            :loading="cptLoading"
                            :close-on-select="false" 
                            :internal-search="false"
                            :show-labels="false"
                            @search-change="debounceCPTCodes"
                            :showNoOptions="true">
                            <template slot="noResult">
                                No codes matched your search
                            </template>
                            <template slot="noOptions">
                                Start typing a code or description...
                            </template>
                        </multiselect>
                    </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-form-group
                        class="ml-2"
                        label="Age Ranges:">
                        <worker-age-range-assignments ref="ageRanges" dummy :worker-id="worker.worker_id" />
                    </b-form-group>
                </b-form-row>
            </b-form>

        <div style="text-align:right">
            <b-button-group>                                
            <b-button class="avo-basic-btn ml-2" @click="hideModal()">Cancel</b-button>
            <b-button class="avo-primary-btn ml-2" @click="updateUserAssignments()" variant="primary">Update Worker assignments</b-button>
            </b-button-group>
        </div>
    </b-modal>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import WorkerAgeRangeAssignments from './WorkerAgeRangeAssignments.vue'

export default {
    name: 'WorkerAssignmentsUpdate',
    props: ['worker', 'index', 'practicesOptions', 'payersOptions', 'payersGroupsOptions'],
    mixins: [alertsMixin],
    components: {
        Multiselect,
        WorkerAgeRangeAssignments
    },
    data(){
        return {
            form: {
                practices: [],
                payers: [],
                codes: [],
            },

            selectedPayerGroups: [],

            selectableCPTCodes: [],
            cptSearchText: "",
            cptLoading: false,

            optionsLoading: true,
        }
    },
    methods: {
        getWorkerAssignments(){
            const vm = this;
            vm.optionsLoading = true;

            const user_assignments_url = make_url("/workers/" + this.worker.worker_id + "/assignments");

            const promise = authenticated_request({
                method: "get",
                url: user_assignments_url,
            }).then(function(response){
                if (response) {
                    vm.form.practices = response.data.practices.map((item)=>{return {text: item.name, value: item.practice_id}});
                    vm.form.payers = response.data.payers.map((item)=>{return {text: item.name, value: item.payer_id}});
                    vm.form.codes = response.data.codes;
                    vm.optionsLoading = false;
                }
            });

            return promise
        },
        updateUserAssignments(){
            const vm = this;
            
            this.$refs.ageRanges.addData();

            const user_assignments_url = make_url("/workers/" + this.worker.worker_id + "/assignments");

            const practices = this.form.practices.map((item) => item.value);
            const payers = this.form.payers.map((item) => item.value);
            const codes = this.form.codes.map((item) => item.code);

            var data = {
                practices: practices,
                payers: payers,
                codes: codes,
            }

            const promise = authenticated_request({
                method: "post",
                url: user_assignments_url,
                data: data
            })
            .then(function(response){
                    vm.$emit('updated');
                    if (response.status == 200) {
                        //vm.getUsers();
                        //vm.showSuccessMessage("User assignments updated");
                        vm.hideModal();
                    }
                }, function(error){
                    vm.setShowAlert(true, error);
            });
            return promise;
        },
        cptSearchChange: function(searchQuery) {
            this.cptLoading = true;
            this.cptSearchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.CPTAutocomplete();
            } else {
                this.cptLoading = false;
            }
        },
        CPTAutocomplete() {
            var vm = this;
            const url = make_url("/reports/codes/autocomplete");
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    search: this.cptSearchText,
                    limit: 10
                }
            }).then(function(response){
                vm.cptLoading = false;
                const codes = response.data.codes;
                vm.selectableCPTCodes = codes || [];
                return codes || [];
            }).catch(function(errors){
                console.log(errors.response)
                vm.setShowAlert(true, errors);
            });
        },
        codeWithDescription(code){
            if (code.code && code.short){
                return (code.code + ": " + code.short)
            }
            return "Code/description missing"
        },
        popPayerGroup(payer_group){
            this.selectedPayerGroups.pop(this.selectedPayerGroups.indexOf(payer_group));
        },
        addPayersByGroup(payer_group){
            var vm = this;
            this.selectedPayerGroups.push(payer_group);
            authenticated_request({
                method: "get",
                url: make_url("/payers/groups/payers").toString(),
                params:{
                    payer_group_id: payer_group.value
                }
            }).then(function(response){
                const payersData = response.data.payers;
                var payerData;
                var found = false;
                for (var index = 0; index < payersData.length; index++){
                    found = false;
                    payerData = {value: payersData[index].payer_id, text: payersData[index].name};
                    for (var index2 = 0; index2 < vm.form.payers.length; index2++){
                        if (vm.form.payers[index2].value == payerData.value){
                            found = true;
                            break;
                        }
                    }
                    if (!found){
                        vm.form.payers.push(payerData)
                    }
                }
            });
        },
        hideModal(){
            this.setShowAlert(false, "");
            var modal_name = 'worker-assignments-modal';
            if (this.index != null){
                modal_name = modal_name + "-" + this.index;
            }
            this.$bvModal.hide(modal_name);
        },
    },
    created(){
        this.debounceCPTCodes = debounce(this.cptSearchChange, 300)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
}
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}
</style>